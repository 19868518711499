export const BG_COVER = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20Cover.jpg?updatedAt=1698138016614`;
export const BG_COUNTING = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20Counting%20Days.jpg?updatedAt=1698138058331`;
export const BG_FOOTER = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20Footer.jpg?updatedAt=1698138016836`;
export const BG_LOVESTORY = `https://user-images.githubusercontent.com/10141928/149857523-805f43ad-0c1b-4300-b72c-3f8e8d37a1f8.jpg`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20Desktop.jpg?updatedAt=1698138013813`;

// images welcome section
export const IMG_LAMPION = `https://user-images.githubusercontent.com/10141928/150158769-8c451a18-4b83-46fe-97d9-3fc8f87f7e06.png`;
export const IMG_WELCOME_1 = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20welcoming_1_edit.jpg?updatedAt=1698138015102`;
export const IMG_WELCOME_2 = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20welcoming_2.jpg?updatedAt=1698257888662`;
export const IMG_WELCOME_3 = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20welcoming_3.jpg?updatedAt=1698138364287`;
export const IMG_WELCOME_4 = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20welcoming_4.jpg?updatedAt=1698257888552`;
export const IMG_WELCOME_5 = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20welcoming_5.jpg?updatedAt=1698138016972`;

// couple image
export const IMG_MAN = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Groom.jpg?updatedAt=1698138018823`;
export const IMG_GIRL = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Bride.jpg?updatedAt=1698138020234`;
export const IMG_PHOTO_COVER = `https://user-images.githubusercontent.com/10141928/150639548-4e4b612b-35c4-4fda-88d7-fa3cdd97295d.png`;
export const IMG_LOGO_WEDDING = `https://user-images.githubusercontent.com/10141928/150645258-2dddbcd4-a473-4d90-b2f5-825d0fe1737e.png`;
export const IMG_LOVE_STORY = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20Love%20Story.jpg?updatedAt=1698138014900`;

// video assets
export const VIDEO_COVER = `https://user-images.githubusercontent.com/10141928/150642624-d39b2ace-41e7-4464-a1ed-1cadc519503a.mp4`;

// image photo gallery cover
export const IMG_PHOTO_1 = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20Cover%20Galery_1.jpg?updatedAt=1698138015701`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20Cover%20Galery_2.jpg?updatedAt=1698138014640`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/Foto%20Cover%20Galery__3.jpg?updatedAt=1698138010747`;
// image icons
export const IMG_ICON_HAND = `https://user-images.githubusercontent.com/10141928/150661877-d975b35b-3e55-4333-b216-2f9df6ca2584.png`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};

export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
